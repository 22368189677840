@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --orange: #f35b2466;
  --orange: #8eaf55;
}

body {
  margin: 0;
  /* font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#react-select-5-listbox {
  z-index: 999999999999999 !important;
}
.NyscBtn1{
  @apply h-6 w-20 bg-[#ECFFCC] text-[#87AA4B] font-bold text-[12px] rounded-lg
}
.NyscBtn2{
  @apply h-6 w-20 bg-[#FFDACC] text-[#F35B24] font-bold text-[12px] rounded-lg
}
.tox-statusbar__branding{
  display: none !important;
}
.question-table table, .question-table col, .answer-table table,.answer-table col {
   border: 1px solid rgb(228, 225, 225) ;   
}
.question-table td, .answer-table td{
  padding-left: 3px;
  padding-top: 6px;
   padding-bottom: 6px;
}
.question-table tr, .answer-table tr{
   border-bottom: 1px solid rgb(228, 225, 225);    
}
.answer-table table{
   width: 300px !important;
}
.focus-border-0:focus, .focus-border-0:active, .focus-border-0:hover{
    border: none ;
    outline: none;
}
.lalHag{
  @apply border border-gray-200 rounded-lg mb-4
}
div[data-tag="allowRowEvents"]{
  white-space: pre-wrap !important;
  text-overflow: unset !important;
}
.admin-sidebar::-webkit-scrollbar{
  display: none;
}
.admin-sidebar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 
@media screen and (max-width: 500px) {
  .m-hidden{
    display: none;
  }
}
.jodit-status-bar{
  display: none !important;
}
.sign-up-container{
  background-image: url('./images/bg2.jpg');
}
.react-daterange-picker__wrapper{
  border: 1px solid #cacaca !important;
  padding: 5px 10px;
  border-radius: 5px;
}