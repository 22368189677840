.Parent_Style {
  width: 100%;
  border-collapse: collapse;
}
.Parent_Style td,
.Parent_Style th {
  text-align: left;
  padding: 1rem 0.85rem;
  vertical-align: center;
  font-size: 12px;
  border-bottom: 1px solid #e8e8e9;
}
.Parent_Style th {
  font-weight: 500;
}
.Parent_Style td {
  color: #4B664C;
}
/* .Parent_Style tbody tr:nth-child(even) {
  background-color: var(--sidebar-bg);
}# sourceMappingURL=Table.css.map */